<template>
  <div class="ym-page">
    <!-- 顶部组件 -->
    <search-form :form="form"
                 @select="select"></search-form>
    <!--分类-->
    <el-row :gutter="12">
      <el-col v-for="(item,index) in statistics"
              :key="index"
              :xs="24"
              :sm="12"
              :md="6">
        <el-card shadow="never">
          <div class="media statistics">
            <div class="media-left">
              <div class="statistics-icon"
                   :style="item.color">
                <img class="statistics-img"
                     :src="item.icon">
              </div>
            </div>
            <div class="media-body shenglue">
              <h5 class="statistics-title" style="text-align: center;">{{item.title}}</h5>
              <h2 style="font-size:1.8vw; text-align: center;">{{getNumber(item.count)}}</h2>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!--图表-->
    <el-row :gutter="12"
            class="small-top small-bottom">
      <el-col :xs="24"
              :sm="12">
        <el-card shadow="never">
          <div class="echarts">
            <span class="echarts-text">数量（个）</span>
            <ledger-type-chart class="ledger-type-chart" :datas="ledgerAggregationData" :ledgerTypeChart="ledgerTypeChart"></ledger-type-chart>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24"
              :sm="12">
        <el-card shadow="never">
          <div class="echarts">
            <span class="echarts-icon">
              <el-button size="mini"
                         :disabled="changeStatus.month"
                         @click="change('month',12)">月</el-button>
              <el-button size="mini"
                         :disabled="changeStatus.day"
                         @click="change('day',7)">日</el-button>
            </span>
            <span class="echarts-text">时间</span>
            <ledger-processing-chart class="ledger-processing-chart" :datas="blockAggregationData" :processingType="processingType"></ledger-processing-chart>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!-- 列表组件 -->
    <div class="table-group small-bottom">
      <div class="table-group-item table-block">
        <ym-table-list stripe
                       :tableOption="{size: 'medium'}"
                       :data="tableListBlock.block"
                       :columns="tableObjListBlock.columnsData"
                       :headData="tableObjListBlock.headData"
                       row-key="id">
          <!-- 使用列表插槽 -->
          <template #height="{row}">
            <span v-if="row.height">{{toThousands(row.height)}}</span>
            <span v-else>无</span>
          </template>
          <template #timestamp="{row}">
            {{ tf(row.timestamp, 'YYYY-MM-DD HH:mm:ss') }}
          </template>
          <template #hash="{row}">
            <span v-if="row.hash">{{row.hash}}</span>
            <span v-else>无</span>
          </template>
        </ym-table-list>
      </div>
    </div>

    <div class="table-group">
      <div class="table-group-item table-ledger">
        <ym-table-list stripe
                       :tableOption="{size: 'medium'}"
                       :data="tableListLedger.ledger"
                       :columns="tableObjListLedger.columnsData"
                       :headData="tableObjListLedger.headData"
                       row-key="id">
          <!-- 使用列表插槽 -->
          <template #timestamp="{row}">
            {{ tf(row.timestamp, 'YYYY-MM-DD HH:mm:ss') }}
          </template>
          <template #hash="{row}">
            <span v-if="row.hash">{{row.hash}}</span>
            <span v-else>无</span>
          </template>
        </ym-table-list>
      </div>
    </div>

  </div>
</template>

<script>
import SearchForm from './component/SearchForm.vue'
import YmTableList from '@/components/TableList/index.vue'
import dataBlock from './dataBlock.js'
import dataLedger from './dataLedger.js'
import ledgerTypeChart from './component/ledgerType-bar-chart.vue'
import ledgerProcessingChart from './component/ledgerProcessing-bar-chart.vue'
import timeFormat from '@/utils/timeFormat.js'
import { getShardName, setShardName } from '@/utils/localStorage.js'
export default {
  components: {
    SearchForm,
    YmTableList,
    ledgerProcessingChart,
    ledgerTypeChart
  },
  data() {
    return {
      processingType: 'line', // 账本处理笔数图标类型
      ledgerTypeChart: 'pie4', // 账本类型图标类型
      // 表单
      form: {
        shard: null, // 分片名（默认全部）
        searchKey: '' // 搜索条件
      },
      tableListBlock: [],
      tableObjListBlock: dataBlock,
      tableListLedger: [],
      tableObjListLedger: dataLedger,

      statistics: [
        {
          icon: require('./img/blockAggregation.png'),
          color: 'background: #F1F8FF;',
          title: '区块统计',
          count: 0
        },
        {
          icon: require('./img/ledgerAggregation.png'),
          color: 'background: #FFF4F8;',
          title: '合约统计',
          count: 0
        },
        {
          icon: require('./img/node.png'),
          color: 'background: #FFF5EE;',
          title: '节点统计',
          count: 0
        },
        {
          icon: require('./img/listNode.png'),
          color: 'background: #F0FBF7;',
          title: '账本统计',
          count: 0
        }
      ],
      ledgerAggregationData: [],
      changeStatus: {
        month: false,
        day: true
      },
      blockAggregationData: []
    }
  },
  mounted() {
    if (getShardName()) {
      this.form.shard = getShardName()
    }
    this.aggregation()
    this.listBlock()
    this.listLedger()
    this.ledgerAggregation()
    this.blockAggregation(this.changeStatus.day ? 'day' : 'month', 7)
    // 新手指引
    this.$nextTick(() => {
      this.$guide.myIntroJs(this.$route.name)
    })
  },
  methods: {
    // 数量单位处理
    getNumber(value) {
      if (value > 9999) {
        let num = Math.round(value / 100) / 100
        return num + '万'
      } else {
        return value
      }
    },
    toBlock() {
      this.$router.push({
        name: 'listblockMore'
      })
    },
    toLedger() {
      this.$router.push({
        name: 'listLedgerMore'
      })
    },
    // 数字加逗号转化为金额类型
    toThousands(num) {
      return this.$publicFunction.toThousands(num)
    },
    // 存储分片名
    select(shard) {
      setShardName(shard)
      // 初始化数据
      this.tableListBlock = []
      this.tableListLedger = []
      this.ledgerAggregationData = []
      this.blockAggregationData = []
      this.statistics[0].count = 0
      this.statistics[1].count = 0
      this.statistics[2].count = 0
      this.statistics[3].count = 0
      // 查询数据
      this.aggregation()
      this.listBlock()
      this.listLedger()
      this.ledgerAggregation()
      this.blockAggregation(this.changeStatus.day ? 'day' : 'month', 7)
    },
    // // 搜索
    // search(form) {
    //   console.log('搜索：', form)
    //   // 重置表单数据
    //   this.form = form
    //   setShardName(this.form.shard)
    //   // 初始化数据
    //   this.tableListBlock = []
    //   this.tableListLedger = []
    //   this.ledgerAggregationData = []
    //   this.blockAggregationData = []
    //   this.statistics[0].count = 0
    //   this.statistics[1].count = 0
    //   this.statistics[2].count = 0
    //   this.statistics[3].count = 0
    //   // 查询数据
    //   this.aggregation()
    //   this.listBlock()
    //   this.listLedger()
    //   this.ledgerAggregation()
    //   this.blockAggregation(this.changeStatus.day ? 'day' : 'month', 7)
    // },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },
    change(type, size) {
      this.changeStatus = {
        month: !this.changeStatus.month,
        day: !this.changeStatus.day
      }
      this.blockAggregation(type, size)
    },
    async aggregation() {
      let options = {
        shard: this.form.shard
      }
      let _options = {
        peerName: this.form.shard,
        keepLive: true
      }
      let res1 = await this.$api.chaindataQL.aggregation(options)
      this.statistics[0].count = res1.data.listBlock.total || 0
      this.statistics[1].count = res1.data.listLedger.total
      let { data } = await this.$api.home.getNodes(_options)
      if (data) {
        this.statistics[2].count = data.length
      }
      let res2 = await this.$api.chaindataQL.actualDifficultyCount(options)
      this.statistics[3].count = res2.data.listLedger.total
    },
    // 账本类型数量
    async ledgerAggregation() {
      let options = {
        shard: this.form.shard
      }
      let res = await this.$api.chaindataQL.ledgerAggregation(options)
      this.ledgerAggregationData = res.data.listLedger.ledgerCodeTerms
    },
    // 帐本处理笔数
    async blockAggregation(type, size) {
      let param = {
        dataFormat: type,
        size: size,
        shard: this.form.shard
      }
      let res = await this.$api.chaindataQL.blockAggregation(param)
      this.blockAggregationData = res.data.listLedger.timestampTerms
    },
    // 区块列表
    async listBlock() {
      let param = {
        page: this.tableObjListBlock.pageData.currentPage - 1,
        // limit: this.tableObjListBlock.pageData.size
        limit: 8,
        shard: this.form.shard
      }
      let res = await this.$api.chaindataQL.listBlock(param)
      this.tableListBlock = res.data.listBlock
      // console.log('listBlock', res)
    },
    // 帐本列表
    async listLedger() {
      let param = {
        page: this.tableObjListLedger.pageData.currentPage - 1,
        // limit: this.tableObjListLedger.pageData.size
        limit: 8,
        shard: this.form.shard
      }
      let res = await this.$api.chaindataQL.listLedger(param)
      this.tableListLedger = res.data.listLedger
      // console.log(res)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./media.scss";
// 省略不换行
.shenglue {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
}
::v-deep.ym-table .ym-table-header .ym-table-header-right .el-button span {
  margin-left: 8px;
  font-weight: 600;
  font-size: 12px;
}
::v-deep .el-card {
  border: 0;
}
.ym-page {
  .tableList__title {
    margin: 12px 0;
  }
  .small-top {
    margin-top: 12px;
  }
  .small-bottom {
    margin-bottom: 12px;
  }
  .statistics {
    &-icon {
      width: 104px;
      height: 104px;
      border-radius: 4px;
      background: #f1f8ff;
      text-align: center;
      & img {
        position: relative;
        width: 42px;
        height: 42px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &-title {
      margin: 16px 0;
    }
  }
  .table-group {
    background: $--color-white;
    border-radius: 4px;
    // margin-bottom: 12px;
    &-head {
      font-size: 16px;
      padding: 10px 25px;
      .head-right {
        padding: 0;
        float: right;
        & a {
          padding: 3px 0;
          font-size: 14px;
          color: $--color-primary;
        }
      }
    }
    &-item {
      ::v-deep.el-table thead .cell {
        font-size: 14px;
      }
      ::v-deep.el-table td:first-child .cell,
      ::v-deep.el-table th:first-child .cell {
        padding-left: 25px;
      }
    }
  }
  .echarts {
    position: relative;
    height: 400px;
    &-icon {
      position: absolute;
      right: 0px;
      z-index: 1;
    }
    &-text {
      position: absolute;
      right: 0px;
      z-index: 1;
      font-size: 12px;
      bottom: 12px;
    }
    ::v-deep.ym-echart-comp {
      height: 100%;
      position: relative;
    }
  }
}
</style>
